<template>
	<Dialog :dialog="activateDialog" :dialog-width="600">
		<template v-slot:title> Activate {{ type }}</template>
		<template v-slot:body>
			<v-row class="delete-dialog">
				<v-col md="2" class="py-0 text-right my-auto">
					<span class="svg-icon svg-icon-lg delete-confirm-icon">
						<!--begin::Svg Icon-->
						<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
						<!--end::Svg Icon-->
					</span>
				</v-col>
				<v-col md="10" class="py-0 my-auto">
					<p class="btx-p m-0">
						Are you sure about activating {{ type }}
						<span class="green--text font-level-1-bold"> {{ activateText }}</span
						>?
					</p>
				</v-col>
				<v-col v-if="activateNote" md="12" class="pb-0 my-auto">
					<em>Note: {{ activateNote }}</em>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="activateButton"
				:disabled="activateButton"
				depressed
				color="green"
				tile
				v-on:click="activateRow()"
			>
				Yes! Activate
			</v-btn>
			<v-btn depressed tile :disabled="activateButton" v-on:click="$emit('close', true)">
				No, Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "activate-template",
	title: "Activate Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		activateUrl: {
			type: String,
			default: null,
		},
		activateText: {
			type: String,
			default: null,
		},
		activateNote: {
			type: String,
			default: null,
		},
		activateDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			activateButton: false,
		};
	},
	components: {
		Dialog,
	},
	methods: {
		activateRow() {
			this.activateButton = true;
			ApiService.patch(this.activateUrl)
				.then(() => {
					this.$emit("success");
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! ${this.type} has been activated.` },
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.activateButton = false;
				});
		},
	},
};
</script>
