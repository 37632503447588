<template>
	<Dialog :dialog.sync="importDialog">
		<template v-slot:title> Import {{ title }} </template>
		<template v-slot:body>
			<v-row>
				<v-col md="12">
					<p class="btx-p">
						Download a <a href="">sample file</a> and compare it to your import file to ensure you have
						the file perfect for the import.
					</p>
				</v-col>
				<v-col md="4">
					<label for="xls-csv-file" class="required btx-label">Upload file</label>
				</v-col>
				<v-col md="8">
					<v-file-input
						id="xls-csv-file"
						v-model="file"
						class="mt-0 pt-0"
						placeholder="Choose File"
						prepend-icon="mdi-paperclip"
					>
						<template v-slot:selection="{ text }">
							<v-chip small label color="primary">
								{{ text }}
							</v-chip>
						</template>
					</v-file-input>
					<p class="btx-small-p">Maximum File Size: 5 MB | File Format: CSV or XLSX</p>
				</v-col>
				<v-col md="4">
					<label for="duplicate-handling" class="required btx-label">Duplicate Handling</label>
				</v-col>
				<v-col md="8">
					<v-radio-group id="duplicate-handling" class="mt-0 pt-0" v-model="duplicate_handling" column>
						<v-radio label="Skip" value="skip"></v-radio>

						<p class="btx-p">
							Retains the records in {{ $APPNAME }} and does not sync the identical records from the import
							file.
						</p>
						<v-radio label="Overwrite" value="overwrite"></v-radio>
						<p class="btx-p">
							Overwrites existing records in {{ $APPNAME }} with the identical records in the import file.
						</p>
					</v-radio-group>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn class="white--text" depressed color="blue darken-4" tile v-on:click="importDocuments">
				Import
			</v-btn>
			<v-btn depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog.vue";
import ApiService from "@/core/services/api.service"; /* 
import { GET_LISTING } from "@/core/services/store/listing.module"; */

export default {
	name: "import-dialog",

	data() {
		return {
			pageTitle: "Assets",
			duplicate_handling: "skip",
			file: null,
		};
	},
	props: {
		importDialog: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
	},
	methods: {
		/* getListing() {
			this.pageLoading = true;

			const relational = new Object({});

			if (this.internal) {
				relational["internal"] = 1;
				relational["type"] = this.relation_type;
				relational["type-id"] = this.relation_type_id;
			}

			const filter = new Object({
				status: "all",
				...this.$route.query,
				...this.defaultFilter,
				...relational,
				tz: new Date().getTime(),
			});
			this.$store
				.dispatch(GET_LISTING, { url: "asset", filter })
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.clearSelection();
					this.pageLoading = false;
				});
		}, */

		importDocuments() {
			ApiService.setHeader();
			const formData = new FormData();
			formData.append("excel_file", this.file, this.file.name);
			formData.append("data_type", this.duplicate_handling);

			ApiService.upload(this.endpoint, formData).then(({ data }) => {
				// this.$store.dispatch(SET_LOCAL_DB);
				console.log(data);

				this.$emit("close", true);
				window.location.reload();
			});
		},
	},
	components: {
		Dialog,
	},
};
</script>
