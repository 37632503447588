<template>
	<v-tooltip top content-class="custom-top-tooltip">
		<template v-slot:activator="{ on, attrs }">
			<v-chip :small="small" label v-bind="attrs" v-on="on">
				{{ humanFormat }}
			</v-chip>
		</template>
		<span>
			{{ systemFormat }}
		</span>
	</v-tooltip>
</template>

<script>
export default {
	props: {
		humanFormat: {
			type: String,
			default: null,
		},
		systemFormat: {
			type: String,
			default: null,
		},
		small: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
