<template>
	<span v-if="tooltip">
		<v-tooltip top content-class="custom-top-tooltip">
			<template v-slot:activator="{ on, attrs }">
				<span
					v-if="copytext() && clickToCopy"
					@click.stop.prevent="copyBarcode(value())"
					:class="{ 'text-truncate': truncate }"
					v-html="value()"
					v-on="on"
					v-bind="attrs"
				>
				</span>
				<span v-else :class="{ 'text-truncate': truncate }" v-html="value()" v-on="on" v-bind="attrs">
				</span>
			</template>
			<span v-html="getTooltipValue()"> </span>
		</v-tooltip>
	</span>
	<span v-else :class="{ 'text-truncate': truncate }" v-html="value()"> </span>
</template>
<script>
import objectPath from "object-path";
import { SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "show-value",
	title: "Show Value",
	props: {
		object: {
			type: Object,
			default() {
				return new Object();
			},
		},
		objectKey: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: null,
		},
		truncate: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: Boolean,
			default: false,
		},
		tooltipValue: {
			type: String,
			default: null,
		},
		tooltipText: {
			type: String,
			default: null,
		},
		clickToCopy: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		copytext() {
			if (objectPath.get(this.object, this.objectKey)) {
				return objectPath.get(this.object, this.objectKey);
			}
			return null;
		},
		value() {
			if (objectPath.has(this.object, this.objectKey)) {
				if (objectPath.get(this.object, this.objectKey)) {
					return objectPath.get(this.object, this.objectKey);
				} else {
					return `<em class="text-muted fw-400">No ${this.label}</em>`.toLowerCase();
				}
			} else {
				return `<em class="text-muted fw-400">No ${this.label}</em>`.toLowerCase();
			}
		},
		getTooltipValue() {
			if (this.tooltipText) {
				return this.tooltipText;
			}
			if (objectPath.has(this.object, this.tooltipValue)) {
				if (objectPath.get(this.object, this.tooltipValue)) {
					return objectPath.get(this.object, this.tooltipValue);
				} else {
					return `<em class="text-muted fw-400">No ${this.label}</em>`.toLowerCase();
				}
			} else {
				return `<em class="text-muted fw-400">No ${this.label}</em>`.toLowerCase();
			}
		},
		copyBarcode(barcode) {
			if (this.clickToCopy && this.copytext()) {
				navigator.clipboard.writeText(barcode);
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `<b>${barcode}</b> - successfully coppied.` },
				]);
			}
		},
	},
};
</script>
