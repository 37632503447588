<template>
	<Dialog :dialog.sync="exportDialog">
		<template v-slot:title> Export {{ title }} </template>
		<template v-slot:body>
			<v-form
				ref="exportForm"
				v-model.trim="export_valid"
				lazy-validation
				v-on:submit.stop.prevent="exportFilter"
			>
				<v-row>
					<v-col md="12">
						<p class="btx-p m-0">You can export your data from {{ $APPNAME }} in CSV, XLSX format.</p>
					</v-col>
					<v-col md="12">
						<v-radio-group hide-details id="export-type" class="mt-0 pt-0" v-model="export_type" column>
							<v-radio :label="`All ${title}`" value="all"></v-radio>
							<v-radio label="Specific Period" value="specific_period"></v-radio>
						</v-radio-group>
					</v-col>
					<template v-if="export_type == 'specific_period'">
						<v-col md="6">
							<label for="export-from-date" class="btx-label required">From</label>
							<DatePicker
								placeholder="From"
								id="export-from-date"
								v-model="export_from_date"
								:rules="[vrules.required(export_from_date, 'From')]"
								:class="{
									required: !export_from_date,
								}"
							></DatePicker>
						</v-col>
						<v-col md="6">
							<label for="export-to-date" class="btx-label required">To</label>
							<DatePicker
								placeholder="To"
								id="export-to-date"
								v-model="export_to_date"
								:minDate.sync="export_from_date"
								:rules="[vrules.required(export_to_date, 'To')]"
								:class="{
									required: !export_to_date,
								}"
							></DatePicker>
						</v-col>
					</template>
					<v-col md="12">
						<label for="export-as" class="btx-label required">Export As</label>
						<v-radio-group hide-details id="export-as" class="mt-2 pt-0" v-model="export_as" column>
							<v-radio label="XLSX (Microsoft Excel)" value="xlsx"></v-radio>
							<!-- <v-radio label="CSV (Comma Separated Value)" value="csv"></v-radio> -->
							<!-- <v-radio label="PDF" value="pdf"></v-radio>
							<v-radio label="Print PDF" value="print_pdf"></v-radio> -->
						</v-radio-group>
					</v-col>

					<v-col md="12">
						<label for="export-as" class="btx-label required">Export Columns</label>
						<!-- {{colDefs}} -->

						<!-- {{colShow}} -->
						<Draggable tag="ul" v-model="colDefs" class="draggable-group" handle=".draggable-drag-icon">
							<template v-if="colDefs.length">
								<template v-for="(col, index) in colDefs">
									<li
										class="draggable-group-item"
										v-if="
											checkSelected(col) && col.key != 'uuid' && col.key != 'action' && col.key != 'image'
										"
										:key="index"
									>
										<v-checkbox
											dense
											v-model="colShow"
											v-bind:value="col.id"
											v-on:change="updateSelected(col)"
											:label="col.name"
											color="cyan"
											hide-details
											class="mt-0 mb-0 mt-2"
										></v-checkbox>
									</li>
								</template>
							</template>
						</Draggable>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn class="white--text" depressed color="blue darken-4" tile v-on:click="exportFilter">
				{{ exportButton }}
			</v-btn>
			<v-btn depressed tile v-on:click="closeModel()"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog.vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import Draggable from "vuedraggable";
import { toSafeInteger, isArray, map } from "lodash";
import DatePicker from "@/view/components/DatePicker.vue";
export default {
	name: "export-dialog",
	data() {
		return {
			export_as: "xlsx",
			export_password: null,
			export_from_date: null,
			export_to_date: null,
			export_valid: true,
			exportData: [],
			colShow: [],
			colDefs: [],
			defaultFilterData: {},
			export_type: "all",
		};
	},
	props: {
		exportDialog: {
			type: Boolean,
			default: false,
		},
		current: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		cols: {
			type: Array,
			default() {
				return [];
			},
		},
		defaultfilter: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	watch: {
		cols: {
			deep: true,
			handler(param) {
				if (param.length > 0) {
					this.colDefs = param;
					this.colShow = map(param, (row) => row.id);
				}
			},
		},
		defaultfilter: {
			deep: true,
			handler(param) {
				this.defaultFilterData = param;
			},
		},
	},
	methods: {
		exportFilter() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.exportForm);
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.exportForm.validate()) {
				return false;
			}
			if (this.colShow.length == 0 && this.title != "Calendar") {
				_this.$store.commit(
					SET_ERROR,
					this.errors.concat([{ model: true, message: "Select At least One Columns" }])
				);
				return false;
			}
			let filter = {
				extensions: _this.export_as,
				export: "all",
				search: _this.search || null,
				columns: _this.colShow,
				from_date: _this.export_from_date || null,
				to_date: _this.export_to_date || null,
			};

			_this.exportAsset(filter);
			this.closeModel();
			//_this.$emit("close", true);
		},
		exportAsset(data) {
			ApiService.setHeader();
			let downloadURL = process.env.VUE_APP_API_URL + this.endpoint;
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			const { query } = this.$route;

			if (data.extensions) {
				downloadURL.searchParams.append("extensions", data.extensions);
			}

			if (data.export) {
				downloadURL.searchParams.append("export", "all");
			}

			if (data.columns) {
				downloadURL.searchParams.append("columns", data.columns);
			}
			if (data.from_date) {
				downloadURL.searchParams.append("from_date", data.from_date);
			}
			if (data.to_date) {
				downloadURL.searchParams.append("to_date", data.to_date);
			}

			/* if (this.listingSearch) {
				downloadURL.searchParams.append("search", this.listingSearch);
			} */

			if (query) {
				map(query, (row, index) => {
					if (row) {
						downloadURL.searchParams.append(`${index}`, row);
					}
				});
			}
			if (this.defaultFilterData) {
				map(this.defaultFilterData, (row, index) => {
					if (row) {
						downloadURL.searchParams.append(`${index}`, row);
					}
				});
			}
			downloadURL.searchParams.append("token", token);
			this.export_from_date = null;
			this.export_to_date = null;
			this.export_type = "all";
			window.open(downloadURL, "_blank");
		},
		selectAll() {
			if (this.checkbox) {
				for (let i = 0; i < this.colDefs.length; i++) {
					this.colShow.push(this.colDefs[i].field);
				}
			} else {
				this.colShow = [];
			}
		},
		updateSelected() {
			if (this.colShow && isArray(this.colShow)) {
				const colDefs = toSafeInteger(this.colShow.length);
				const CheckbosLength = toSafeInteger(this.colDefs.length);
				if (CheckbosLength == colDefs) {
					this.checkbox = true;
				} else {
					this.checkbox = false;
				}
			}
		},
		checkSelected(cols) {
			if (this.show_selected) {
				return cols.status;
			}
			return true;
		},
		closeModel() {
			this.colDefs = this.cols;
			this.colShow = map(this.cols, (row) => row.id);
			(this.export_as = "xlsx"), this.$emit("close", true);
		},
	},
	components: {
		Dialog,
		Draggable,
		DatePicker,
	},

	computed: {
		...mapGetters(["listingSearch", "errors"]),
		exportButton() {
			return this.current ? "Export Current View" : "Export";
		},
	},
};
</script>
