<template>
	<Dialog :dialog.sync="filterDialog" :dialog-width.sync="dialogWidth">
		<template v-slot:title>Filter {{ title == "Inventories" ? "Perishables" : title }}</template>
		<template v-slot:body>
			<v-row>
				<v-col v-for="(filter, index) in btxFilter" :key="index" md="6" class="my-auto py-0">
					<label :for="filter.model" class="btx-label mt-2">{{ filter.label }}</label>

					<template v-if="filter.filter_type == 'text'">
						<TextInput
							hide-details
							:id="filter.model"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></TextInput>
					</template>
					<template v-if="filter.filter_type == 'email'">
						<EmailInput
							hide-details
							:id="filter.model"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></EmailInput>
					</template>
					<template v-if="filter.filter_type == 'phone'">
						<PhoneInput
							hide-details
							:id="filter.model"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></PhoneInput>
					</template>
					<template v-if="filter.filter_type == 'date'">
						<DatePicker
							hide-details
							:id="filter.model"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></DatePicker>
					</template>
					<template v-if="filter.filter_type == 'date-range'">
						<DateRangePickerInput
							range
							:id="filter.model"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></DateRangePickerInput>
					</template>
					<template v-if="filter.filter_type == 'select' && filter.model === 'supplier'">
						<AutoCompleteInput
							hide-details
							:items.sync="supplierList"
							item-text="text"
							item-value="value"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></AutoCompleteInput>
					</template>
					<template v-if="filter.filter_type == 'text_number'">
						<TextInput
							hide-details
							:id="filter.model"
							type="number"
							:placeholder="filter.label"
							v-model.number="btxQuery[filter.text]"
						></TextInput>
					</template>
					<template v-if="filter.filter_type == 'select' && filter.model === 'product'">
						<AutoCompleteInput
							hide-details
							:items.sync="allProductList"
							item-text="text"
							item-value="value"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></AutoCompleteInput>
					</template>
					<template v-if="filter.filter_type == 'select' && filter.model === 'type'">
						<AutoCompleteInput
							hide-details
							:items.sync="productHistoryType"
							item-text="text"
							item-value="value"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></AutoCompleteInput>
					</template>
					<template v-if="filter.filter_type == 'select' && filter.model === 'inventories.category'">
						<AutoCompleteInput
							hide-details
							:items.sync="groups"
							item-text="text"
							item-value="text"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></AutoCompleteInput>
					</template>
					<template v-if="filter.filter_type == 'select' && filter.model === 'inventories.manufacturer'">
						<AutoCompleteInput
							hide-details
							:items.sync="manufactureList"
							item-text="name"
							item-value="name"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></AutoCompleteInput>
					</template>
					<template v-if="filter.filter_type == 'select' && filter.model === 'inventories.supplier'">
						<AutoCompleteInput
							hide-details
							:items.sync="supplierList"
							item-text="text"
							item-value="value"
							:placeholder="filter.label"
							v-model="btxQuery[filter.text]"
						></AutoCompleteInput>
					</template>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				depressed
				color="blue darken-4"
				v-on:click="$emit('do-search', btxQuery)"
				tile
			>
				Filter
			</v-btn>
			<v-btn depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
		</template></Dialog
	>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import PhoneInput from "@/view/components/Phone";
import DateRangePickerInput from "@/view/components/DateRangePicker";
import { toSafeInteger } from "lodash";
import DatePicker from "@/view/components/DatePicker";
import { mapGetters } from "vuex";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { GetAllProduct } from "@/core/lib/common.lib";
import { /* filter */ map } from "lodash";
export default {
	name: "filter-template",
	data() {
		return {
			productHistoryType: [
				{
					text: "quotation",
					value: "quotation",
				},
				{
					text: "purchase order",
					value: "purchase-order",
				},
			],
			allProductList: [],
			supplierList: [],
			categoryList: [],
			manufactureList: [],
			groups: [],
		};
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		filterDialog: {
			type: Boolean,
			default: false,
		},
		btxFilter: {
			type: Array,
			default() {
				return [];
			},
		},
		btxQuery: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	components: {
		Dialog,
		TextInput,
		EmailInput,
		PhoneInput,
		DatePicker,
		DateRangePickerInput,
		AutoCompleteInput,
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 60);
		},
		...mapGetters(["errors", "localDB"]),
	},
	methods: {
		getAllProduct() {
			GetAllProduct()
				.then(({ list }) => {
					this.allProductList = map(list, (row) => {
						return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	mounted() {
		this.supplierList = this.localDB("suppliers", []);
		this.categoryList = this.localDB("categories", []);
		this.manufactureList = this.localDB("product_manufacturer", []);
		this.groups = this.localDB("groups", []);
		this.getAllProduct();
	},
};
</script>
