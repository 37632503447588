var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field',{staticClass:"pt-0",class:{
			'mt-3': !_vm.hideTopMargin,
		},attrs:{"id":_vm.id,"label":_vm.label,"loading":_vm.loading,"disabled":_vm.disabled,"hide-details":_vm.hideDetails,"append-icon":_vm.appendIcon,"outlined":"","rules":_vm.rules.concat([
				_vm.vrules.validEmail(_vm.textinput, 'Email Address'),
				_vm.validationField && _vm.validationField.url_type && _vm.fieldNameError ? false : true,
			]),"placeholder":_vm.placeholder},on:{"change":function($event){_vm.validateEmailName();
			_vm.$emit('onChange', true);},"keyup":function($event){return _vm.$emit('keyup', true)},"keydown":function($event){_vm.$emit('keydown', $event), _vm.sanitizeSpecialCharacter($event)}},model:{value:(_vm.textinput),callback:function ($$v) {_vm.textinput=$$v},expression:"textinput"}}),(_vm.fieldNameError)?_c('span',{staticClass:"red--text text--darken-1 font-small",domProps:{"innerHTML":_vm._s(_vm.fieldNameError)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }