<template>
	<div class="text-uppercase">
		<template v-if="tooltip">
			<v-tooltip top content-class="custom-top-tooltip">
				<template v-slot:activator="{ on, attrs }">
					<v-chip
						:small="small"
						:class="customClass"
						:color="statusObject ? statusObject.color : ''"
						label
						v-bind="attrs"
						v-on="on"
						outlined
					>
						{{ statusObject ? statusObject.text : "" }}
					</v-chip>
				</template>
				<span>
					{{ tooltipText }}
				</span>
			</v-tooltip>
		</template>
		<template v-else>
			<v-chip
				:small="small"
				:class="customClass"
				:color="statusObject ? statusObject.color : ''"
				label
				outlined
			>
				{{ statusObject ? statusObject.text : "" }}
			</v-chip>
		</template>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { find } from "lodash";

export default {
	name: "btx-chip",
	props: {
		status: {
			type: String,
			default: null,
		},
		small: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: Boolean,
			default: false,
		},
		customClass: {
			type: String,
			default: null,
		},
		tooltipText: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters(["listingStatus"]),
		statusObject() {
			return find(this.listingStatus, { value: this.status });
		},
	},
};
</script>
