<template>
	<v-menu
		ref="datepicker"
		v-model="datepicker"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="auto"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				:id="id"
				:label="label"
				:loading="loading"
				:disabled="disabled"
				:clearable="clearable"
				outlined
				class="pt-0"
				:class="{ 'mt-2': !hideTopMargin }"
				v-model="dateFormatted"
				:placeholder="btxPlaceholder"
				hide-details
				v-on:click:clear="$emit('click:clear', true)"
				prepend-inner-icon="mdi-calendar"
				v-bind="attrs"
				v-on:blur="date = parseDateArray(dateFormatted)"
				v-on="on"
			></v-text-field>
		</template>
		<v-date-picker v-model="date" no-title range :min="minDate" :max="maxDate"></v-date-picker>
	</v-menu>
</template>

<script>
import { last, head } from "lodash";
export default {
	name: "date-range-picker",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Array, String],
			default: null,
		},
		minDate: {
			type: [Date, String],
			default: null,
		},
		maxDate: {
			type: [Date, String],
			default: null,
		},
		placeholder: {
			type: String,
			default: "Date",
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			date: [],
			datepicker: false,
			dateFormatted: null,
		};
	},
	watch: {
		minDate() {
			this.date = [];
		},
		maxDate() {
			this.date = [];
		},
		date() {
			// if (this.value && this.value.length > 1) {
			// 	this.date = this.value;
			// }

			this.$nextTick(() => {
				this.dateFormatted = this.formatDate(this.date);
				this.$emit("input", this.date);
			});
		},
	},
	methods: {
		formatDate(dates) {
			if (!dates) return null;
			const startDate = head(dates);
			const endDate = last(dates);
			if (!startDate || !endDate) {
				return null;
			}
			return this.splitDate(startDate) + " - " + this.splitDate(endDate);
		},
		splitDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		parseDateArray(dates) {
			if (!dates) return [];
			const dateArray = dates.split(" - ");
			const startDate = head(dateArray);
			const endDate = last(dateArray);
			return [this.parseDate(startDate), this.parseDate(endDate)];
		},
		parseDate(date) {
			if (!date) return null;
			const [day, month, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},
	},
	computed: {
		btxPlaceholder() {
			return this.placeholder + " [DD/MM/YYYY] - [DD/MM/YYYY]";
		},
	},
	mounted() {
		this.date = this.value;
	},
};
</script>
