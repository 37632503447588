<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				<template v-if="title">
					{{ title }}
				</template>
				<template v-else> Issues </template>
			</v-flex>
			<v-flex
				class="text-right"
				v-if="relationType != 'supplier' && relationType != 'project' && relationType != 'member'"
			>
				<v-btn v-on:click="getListing()" color="blue darken-4 text-white" class="mx-2" depressed tile
					><v-icon small left>mdi-reload</v-icon> Reload</v-btn
				>
				<v-btn color="blue darken-4 text-white" class="ml-2" depressed tile
					><v-icon small left>mdi-playlist-plus</v-icon>New Asset</v-btn
				>
			</v-flex>
		</v-layout>
		<Table internal :page-loading="pageLoading" type="checkout" :detail-route="null"></Table>
		<!-- <Table internal :page-loading="pageLoading" type="asset" detail-route="asset-detail"></Table> -->
	</div>
</template>
<script>
import Table from "@/view/components/Table";
import ListingMixin from "@/core/mixins/listing.mixin";

export default {
	name: "asset-internal-listing",
	title: "Listing Internal Asset",
	mixins: [ListingMixin],
	components: {
		Table,
	},
	data() {
		return {
			pageTitle: "Issues",
			endpoint: "reservation",
			internal: true,
			relation_type: null,
			relation_type_id: 0,
			defaultFilter: { status: "checked-out" },
		};
	},
	props: {
		relationType: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		title: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: null,
		},
		relationTypeId: {
			type: Number,
			default: 0,
		},
	},
	methods: {
		init() {
			this.relation_type = this.relationType;
			this.relation_type_id = this.relationTypeId;
			if (this.type) {
				this.defaultFilter.request_type = this.type;
			}
			if (this.status) {
				this.defaultFilter.status = this.status;
			}
		},
	},
	beforeMount() {
		this.init();
	},
};
</script>
